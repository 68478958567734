import { EmailSvg2, Phone_Svg, RepresentativeSvg } from "../../../components/CommonSvg";

export const serviceInitialValue = (value) => {
  return {
    startDate: value?.agreementStartDate || value?.subHra?.agreementStartDate || "",
    endDate: value?.agreementEndDate || value?.subHra?.agreementEndDate || "",
  };
};

export const renderColumnData = (data, columnKey) => {
  console.log(data, "columnKey")
  switch (columnKey) {
    case 'achEnrolled':
      return <span>{data?.achEnrolled || <>&nbsp;</>}</span>;
    case 'accountName':
      return <span>{data?.accountName ? data?.accountName : <>&nbsp;</>}</span>;
    case 'bbgStatus':
      return <span>{data?.bbgStatus || <>&nbsp;</>}</span>;
    case 'businessPhone':
      return (
        <p>
          <Phone_Svg />
          {data.businessPhone || <>&nbsp;</>}
        </p>
      );
    case 'city':
      return <span>{data?.city || <>&nbsp;</>}</span>;
    case 'cokeBottlerRegion':
      return <span>{data?.cokeBottlerRegion || <>&nbsp;</>}</span>;
    case 'contactName':
      return <span>{data?.contactName || <>&nbsp;</>}</span>;
    // case 'createdBy':
    //   return <span>{data?.createdBy || <>&nbsp;</>}</span>;
    // case 'createdDate':
    //   return <span>{data?.createdDate || <>&nbsp;</>}</span>;
    case 'dateEnrolled':
      return <span>{data?.dateEnrolled || <>&nbsp;</>}</span>;
    case 'ein':
      return <span>{data?.ein || <>&nbsp;</>}</span>;
    case 'emailLogin':
      return <span>{data?.emailLogin || <>&nbsp;</>}</span>;
    case 'inactiveDate':
      return <span>{data?.inactiveDate ? data?.inactiveDate : <>&nbsp;</>}</span>;
    case 'inactiveReason':
      return <span>{data?.inactiveReason || <>&nbsp;</>}</span>;
    case 'leadsRequests':
      return <span>{data?.leadsRequests || <>&nbsp;</>}</span>;
    case 'mailingAddressCity':
      return <span>{data?.mailingAddressCity || <>&nbsp;</>}</span>;
    case 'mailingState':
      return <span>{data?.mailingState || <>&nbsp;</>}</span>;
    case 'mailingStreetAddress':
      return <span>{data?.mailingStreetAddress || <>&nbsp;</>}</span>;
    case 'mailingZip':
      return <span>{data?.mailingZip || <>&nbsp;</>}</span>;
    case 'masterDase':
      return <span>{data?.masterDase || <>&nbsp;</>}</span>;
    case 'memberIdNum':
      return <span>{data?.memberIdNum || <>&nbsp;</>}</span>;
    case 'oldHra':
      return <span>{data?.oldHra || <>&nbsp;</>}</span>;
    case 'postalCode':
      return <span>{data?.postalCode || <>&nbsp;</>}</span>;
    case 'rbnaDistributor':
      return <span>{data?.rbnaDistributor || <>&nbsp;</>}</span>;
    case 'relationship':
      return <span>{data?.relationship || <>&nbsp;</>}</span>;
    case 'secondaryEmailAddress':
      return <span>{data?.secondaryEmailAddress || <>&nbsp;</>}</span>;
    case 'state':
      return <span>{data?.state || <>&nbsp;</>}</span>;
    case 'status':
      return <span>{data?.status || <>&nbsp;</>}</span>;
    case 'storeCreatedDate':
      return <span>{data?.storeCreatedDate || <>&nbsp;</>}</span>;
    case 'storeNameHra':
      return <span>{data?.storeNameHra || <>&nbsp;</>}</span>;
    case 'storeNameRegular':
      return <span>{data?.storeNameRegular || <>&nbsp;</>}</span>;
    case 'streetAddress':
      return <span>{data?.streetAddress || <>&nbsp;</>}</span>;
    case 'territory':
      return <span>{data?.territory?.[0]?.Name || <>&nbsp;</>}</span>;
    case 'ticketOwner':
      return <span>{data?.ticketOwner || <>&nbsp;</>}</span>;
    // case 'updatedBy':
    //   return <span>{data?.updatedBy || <>&nbsp;</>}</span>;
    // case 'updatedDate':
    //   return <span>{data?.updatedDate || <>&nbsp;</>}</span>;
    case 'userName':
      return <span>{data?.userName || <>&nbsp;</>}</span>;
    case 'w9':
      return <span>{data?.w9 || <>&nbsp;</>}</span>;
    case 'county':
      return <span>{data?.county || <>&nbsp;</>}</span>;

    default:
      return <></>;
  }
};
