import React, { useEffect, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import { useLocation, useParams } from "react-router-dom";
import {
  getMemberDetailsRequest,
  getProfileVendorRequest,
} from "../../../redux/admin/members/action";
import { connect } from "react-redux";
import { EditbtnSvg } from "../../../components/CommonSvg";
import AddEditAccountInfo from "./AddEditAccountInfo";
import { getValueByKey } from "../../../utils/authUtils";

const ViewMember = (props) => {
  const { vendorProfile, getVendors } = props;
  const [MemberData, setMemberData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [editAccountNum, setEditAccountNum] = useState(false);
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      props.getMemberDetailsList({ hraMemberId: params.id });
    }
  }, []);

  useEffect(() => {
    if (props.memberDetails) {
      setMemberData(props.memberDetails);
      setTerritoryData(
        props.memberDetails?.territory?.[0]
          ? props.memberDetails?.territory?.[0]
          : []
      );
    }
  }, [props.memberDetails]);
  const vendorNumbers = MemberData?.vendorNumbers
    ? JSON.parse(MemberData?.vendorNumbers)
    : [];
  useEffect(() => {
    getVendors();
  }, []);
  const isVendorAccess = getValueByKey("vendorAccess");
  return (
    <>
      <CommonBackHeader
        backPath={"/admin/members"}
        HeaderName={"View Member"}
      />
      <div className="container hra-view-detail view_member_card">
        <div className="hra-view-detail-inner-box">
          <Card className="p-0 mb-3">
            <CardBody className="row">
              <div className="d-flex justify-content-between mb-2">
                <h6 className="separate-head">Basic Details</h6>
                <div className="view_member_head">
                  <span className="view_member_hra">
                    {MemberData.hraMemberId || "--"}
                  </span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>Name</label>
                  <span>
                    {(MemberData.firstName !== null
                      ? MemberData.firstName
                      : "--") +
                      " " +
                      (MemberData.lastName !== null ? MemberData.lastName : "")}
                  </span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Account Name</label>
                  <span>{MemberData.accountName || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>Email Address</label>
                  <span>{MemberData.emailAddress || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>secondary Email Address</label>
                  <span>{MemberData.secondaryEmailAddress || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>Mobile Number</label>
                  <span>{MemberData.phoneNumberMobileNumber || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>relationship</label>
                  <span>{MemberData.relationship || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>status</label>
                  <span>{MemberData.status || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>leads Requests</label>
                  <span>{MemberData.leadsRequests || "--"}</span>
                </div>
              </div>
              {/* <div className="col-3">
                <div className="hra-form-box">
                  <label>light On a Hill Account Number</label>
                  <span>{MemberData.lightOnaHillAccountNumber || "--"}</span>
                </div>
              </div> */}
            </CardBody>
          </Card>
          <Card className="p-0 mb-3">
            <CardBody className="row">
              <div className="mb-2">
                <h6 className="separate-head">Business Details</h6>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Member ID</label>
                  <span>{MemberData.memberIdNum || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>old Hra</label>
                  <span>{MemberData.oldHra || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>contact Name</label>
                  <span>{MemberData.contactName || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>ach Enrolled</label>
                  <span>{MemberData.achEnrolled || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>bbg Status</label>
                  <span>{MemberData.bbgStatus || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>business Phone</label>
                  <span>{MemberData.businessPhone || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>city</label>
                  <span>{MemberData.city || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>coke Bottler Region</label>
                  <span>{MemberData.cokeBottlerRegion || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>county</label>
                  <span>{MemberData.county || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>date Enrolled</label>
                  <span>{MemberData.dateEnrolled || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>ein</label>
                  <span>{MemberData.ein || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>email Login</label>
                  <span>{MemberData.emailLogin || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>inactive Date</label>
                  <span>{MemberData.inactiveDate || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Inactive Reason</label>
                  <span>{MemberData.inactiveReason || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>mailing Address City</label>
                  <span>{MemberData.mailingAddressCity || "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>mailing State</label>
                  <span>{MemberData.mailingState || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>mailing Street Address</label>
                  <span>{MemberData.mailingStreetAddress || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>mailing Zip</label>
                  <span>{MemberData.mailingZip || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>master Dase</label>
                  <span>{MemberData.masterDase || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>postal Code</label>
                  <span>{MemberData.postalCode || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>rbna Distributor</label>
                  <span>{MemberData.rbnaDistributor || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>state</label>
                  <span>{MemberData.state || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>store Name Hra</label>
                  <span>{MemberData.storeNameHra || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>store Name Regular</label>
                  <span>{MemberData.storeNameRegular || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>street Address</label>
                  <span>{MemberData.streetAddress || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>ticket Owner</label>
                  <span>{MemberData.ticketOwner || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>user Name</label>
                  <span>{MemberData.userName || "--"}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>territory Name</label>
                  <span>{territoryData?.Name ? territoryData.Name : "--"}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="hra-form-box">
                  <label>w9</label>
                  <span>{MemberData.w9 === "0" ? "No" : "Yes" || "--"}</span>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-0 mb-3">
            <CardBody className="row">
              {!editAccountNum ? (
                <>
                  <div className="mb-2  d-flex align-items-center edit-head">
                    <h6 className="separate-head">Vendors Account Number</h6>
                    {isVendorAccess === "true" && (
                      <Button
                        color="primary"
                        className="d-flex gap-1 align-items-center"
                        size="sm"
                        title="Edit"
                        onClick={() => setEditAccountNum(true)}
                      >
                        <EditbtnSvg />
                        Edit
                      </Button>
                    )}
                  </div>
                  {vendorProfile?.length > 0 &&
                    vendorProfile.map((x) => {
                      const { apiName } = x;
                      const accNumber =
                        vendorNumbers.find((item) => item.apiKey === apiName)
                          ?.accNumber || "--";
                      return (
                        <div className="col-3" key={apiName}>
                          <div className="hra-form-box">
                            <label>{`${apiName.replace(/_/g, " ")} #`}</label>
                            <span>{accNumber || "--"}</span>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  <AddEditAccountInfo
                    // profileDetail={profileDetail}
                    hraMemberId={MemberData.hraMemberId}
                    setEditAccountNum={setEditAccountNum}
                    vendorNumbers={vendorNumbers}
                    vendorProfile={vendorProfile}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Members }) => {
  let memberDetails;
  let vendorProfileList =
    Members.proVendor?.list?.length > 0 ? Members.proVendor.list : [];
  if (Members?.memberDetailsApiResponse) {
    memberDetails = Members?.memberDetailsApiResponse;
  }
  return {
    memberDetails: memberDetails,
    vendorProfile: vendorProfileList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMemberDetailsList: (data) => {
      dispatch(getMemberDetailsRequest(data));
    },
    getVendors: () => {
      dispatch(getProfileVendorRequest());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewMember);
